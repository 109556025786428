<template>
  <Popup
    class="post-payment-popup"
    :width="width"
    :margin="margin"
    :radiusType="radiusType"
    :closePopupEvent="closePopup"
  >
    <!--Showing Loader Or Payment Error-->
    <div class="precheck" slot="component" >
      <Loading v-if="loader"></Loading>
      <p class="paymentError" v-else>{{ paymentError }}</p>
    </div>

    <!--Payment Response Handling-->
    <div class="paymentSuccess" slot="component">
      <!--Shoing Payment In Progress UI-->
      <div v-if="!loader && !transactionSuccess">
        <p class="please-wait">{{ $t("please wait") }}</p>
        <p class="payment-progress">{{ $t("your payment is in progress") }}</p>
        <img class="loading-gif" src="@/assets/gif/30.gif" />
      </div>

      <!--Showing Loader-->
      <div v-else-if="loader && !transactionSuccess">
        <Loading></Loading>
      </div>

      <!--Payment Success Response Handling-->
      <div v-else-if="!loader && transactionSuccess">
        <p class="payment-successfull">{{ $t(paymentSuccessResponse) }}</p>
        <div
          :class="[
            localDisplayLang === 'ara'
              ? 'subscription-success-card-right'
              : 'subscription-success-card',
          ]"
        >
          <img class="tick" src="@/assets/icons/Check1.svg" />
          <p class="plan-title">
            {{ transactionResponse.paymentDetail.planname }}
          </p>
          <p class="plan-interval" dir="ltr" v-if="localDisplayLang === 'ara'">
            {{
              retrievePlanInterval(
                transactionResponse.paymentDetail,
                localDisplayLang
              )
            }}
          </p>
          <p class="plan-interval" dir="ltr" v-else>
            {{
              retrievePlanInterval(
                transactionResponse.paymentDetail,
                localDisplayLang
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
import { eventBus } from '../../eventBus';
export default {
  props: ["transactionResponse"],
  data() {
    return {
      isPaymentSuccess: false,
      loader: false,
      width: "35%",
      margin: "20% auto",
      paymentWidth: "60%",
      paymentMargin: "10% auto",
      radiusType: "full",
      paymentSuccessResponse: "",
      transactionType: null,
      localDisplayLang : null,
			planDiscountCheckout: null
    };
  },
 computed: {
    ...mapGetters(["appConfig"]),
	},

  created() {

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

        console.log("THE NEW POPUP FIX -- > ", this.transactionResponse);

    if (this.transactionResponse && this.transactionResponse.response) {
      if (!this.transactionResponse.response.reason) {
        this.showPayment = false;
        this.isPaymentSuccess = true;
        this.transactionType = "SUBSCRIPTION";
        this.paymentSuccessResponse =
          "You have successfully subscribed to plan";
        this.transactionSuccess = true;
        this.loader = false;
        this.setNewSubscription(true);

        //free subscription event

        let payload = {
          Subscription_Start_Date:
            this.transactionResponse.response.planstartdate,
          Subscription_End_Date: this.transactionResponse.response.nextbilling,
          Transaction_ID: "",
          Amount: 0,
          Currency_Code: this.transactionResponse.response.currency,
          Payment_ID: "",
          Promo_Code: this.transactionResponse.response.couponid,
          Discount_Amount: this.transactionResponse.response.discount,
          Plan_ID: this.transactionResponse.response.planid,
          Plan_Name: this.transactionResponse.response.planname,
        };

        //cevertap event
        this.chargedCleverEvent(payload);

        //branch purchase subscribe event
        this.purchaseSubscribeBranchEvent(payload);
      } else {
        this.isPaymentSuccess = false;
        this.paymentError = response.reason;
      }
    }
this.planDiscountCheckout = this.appConfig.planDiscountCheckout;
  },

  methods: {
    ...mapMutations(["setNewSubscription"]),
    closePopup() {
        eventBus.$emit("toggleSuccessMessagePopupClose", false);
    },

    retrievePlanInterval(paymentDetail, displayLanguage) {
			if(paymentDetail.detail.planid === "C8I2TIVK"){
				if (displayLanguage == "ara") {
        return (
          (paymentDetail.planinterval === "QUARTER"
            ? "3" + " " + this.$t("quarter")
            : this.$t(paymentDetail.planinterval)) +
          " " +
          "/" +
          " " +
          paymentDetail.currency +
          " " +
          this.planDiscountCheckout ? this.planDiscountCheckout.offerPrice : paymentDetail.amount / 100
        );
      } else {
				console.log("pppaymentDetail", paymentDetail)

        return (
          paymentDetail.currency +
          " " +
           this.planDiscountCheckout ? this.planDiscountCheckout.offerPrice : paymentDetail.amount / 100 +
          " " +
          "/" +
          " " +
          (paymentDetail.planinterval === "QUARTER"
            ? this.$t("quarter")
            : this.$t(paymentDetail.planinterval))
        );
      //}
			}
			}else {
				if (displayLanguage == "ara") {
        return (
          (paymentDetail.planinterval === "QUARTER"
            ? "3" + " " + this.$t("quarter")
            : this.$t(paymentDetail.planinterval)) +
          " " +
          "/" +
          " " +
          paymentDetail.currency +
          " " +
          paymentDetail.amount / 100
        );
      } else {
        return (
          paymentDetail.currency +
          " " +
          paymentDetail.amount / 100 +
          " " +
          "/" +
          " " +
          (paymentDetail.planinterval === "QUARTER"
            ? this.$t("quarter")
            : this.$t(paymentDetail.planinterval))
        );
      }
			}
    },
  },

  components: {
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      ),
    Popup: () =>
      import(/* webpackChunkName: "popup" */ "@/components/Slots/Popup.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.vlive-payment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.precheck {
  color: #fff;
  padding: 2.6rem;
}
.paymentError {
  color: #efeff4;
  font-family: $font-regular;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.paymentSuccess {
  padding: 2.6rem;
  color: #efeff4;
  font-family: $font-regular;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  .please-wait {
    color: #b8c0c7;
    font-family: $font-regular;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    text-align: center;
  }
  .payment-progress {
    color: #70787f;
    font-family: $font-regular;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 27px;
  }
  .loading-gif {
    width: 85px;
  }
  .payment-successfull {
    color: #b8c0c7;
    font-family: $font-regular;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  .subscription-success-card {
    border: 1px solid #32363e;
    border-radius: 10px;
    background-color: #191b20;
    position: relative;
    padding: 0.6rem;
    text-align: left;
    .tick {
      position: absolute;
      top: -5px;
      right: -5px;
    }
    .plan-title {
      color: #f8fafd;
      font-family: $font-regular;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
    }
    .plan-type {
      color: #70787f;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 27px;
    }
    .plan-interval {
      color: #4d7bff;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
  .subscription-success-card-right {
    border: 1px solid #32363e;
    border-radius: 10px;
    background-color: #191b20;
    position: relative;
    padding: 0.6rem;
    text-align: right;
    .tick {
      position: absolute;
      top: -5px;
      left: -5px;
    }
    .plan-title {
      color: #f8fafd;
      font-family: $font-regular;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
    }
    .plan-type {
      color: #70787f;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 27px;
    }
    .plan-interval {
      color: #4d7bff;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
  .purchase-success-card {
    .poster-card {
      height: 150px;
      width: 100px;
      background-size: contain;
      background-position: center;
      border-radius: 5px;
      margin: 0 auto;
      position: relative;
      margin-bottom: 10px;
      .purchase-tick {
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }
    .purchase-content-title {
      color: #cecece;
      font-family: $font-regular;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.39px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .vlive-payment {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
